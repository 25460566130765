import { render, staticRenderFns } from "./OverlayMain.vue?vue&type=template&id=02164ee4&"
import script from "./OverlayMain.vue?vue&type=script&lang=js&"
export * from "./OverlayMain.vue?vue&type=script&lang=js&"
import style0 from "./sass/index.scss?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "themes/resources/vue/components/OverlayMain/OverlayMain.vue"
export default component.exports