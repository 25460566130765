var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", { class: _vm.cn.root }, [
    _c("span", { class: _vm.cn.inner }, [
      _c("span", { class: _vm.cn.sep }),
      _vm._v(" "),
      _c("span", { class: _vm.cn.sep }),
      _vm._v(" "),
      _c("span", { class: _vm.cn.sep })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }