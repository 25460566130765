<template>
    <button class="overlay-main-btn-close" v-bind:class="classList">
        <svg-icon name="close" mod="overlay-main-btn-close__icon"/>
    </button>
</template>

<script>
  export default {
    props: {
      small: Boolean
    },
    computed: {
      classList() {
        return {
          '_small': this.small
        }
      }
    }
  }
</script>