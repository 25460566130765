import spotTemplate from './spot.twig';
import { brick } from 'utils/brick';

import {addClass, removeClass} from 'utils/dom';

class SpotInfo {
  constructor (params, tooltipParams) {
    /**
     * params {Object}
     *   x {Number} hotspot location in degrees for x axis
     *   y {Number} hotspot location in degrees for y axis
     *
     * tooltipParams {Object}
     *   icon {String} icon name '/assets_dev/svg/single/spot'
     *   title {String} optional title for spot content
     *   text {String} optional text for spot content
     */
    
    this.data = {
      'pitch': params.y || 0,
      'yaw': params.x || 0,
      'type': 'info',
      createTooltipFunc: hotSpotDiv => this._renderTooltip(hotSpotDiv)
    };
    
    this.tooltipOptions = tooltipParams;
    
    this.isOpen = false;
    
    this.elements = {
      preview: null,
      container: null,
      inner: null,
      content: null
    };
    
    this.toggleHandler = () => this.toggle();
    this.closeHandler = (e) => {
      e.stopPropagation();
      this.close();
    };
  }
  
  _renderTooltip (hotSpotDiv) {
    const $spotDiv = brick(hotSpotDiv);
    
    $spotDiv.addClass('spot-info');
    
    const {tooltipOptions} = this;
    
    const html = spotTemplate({
      icon: tooltipOptions.icon,
      title: tooltipOptions.title,
      text: tooltipOptions.text
    });
    
    hotSpotDiv.insertAdjacentHTML('beforeend', html);
    
    const spotInnerPreview = hotSpotDiv.querySelector('[data-spot="preview"]');
    const spotInnerDom = hotSpotDiv.querySelector('[data-spot="inner"]');
    const spotContentDom = hotSpotDiv.querySelector('[data-spot="content"]');
    const spotCloseDom = hotSpotDiv.querySelector('[data-spot="close"]');
    
    this.elements = {
      preview: spotInnerPreview,
      container: hotSpotDiv,
      inner: spotInnerDom,
      content: spotContentDom,
      close: spotCloseDom
    };
    
    const $spotClose = brick(spotCloseDom);
    
    $spotDiv.on('click', this.toggleHandler);
    $spotClose.on('click', this.closeHandler);
  }
  
  open () {
    const tour = window.tour;
    tour.dispatchSpotInfoOpen(this.data);
  
    this.isOpen = true;
    addClass(this.elements.container, 'is-open');
  }
  
  close () {
    this.isOpen = false;
    removeClass(this.elements.container, 'is-open');
  }
  
  toggle () {
    this.isOpen ? this.close() : this.open();
  }
}

export default SpotInfo;