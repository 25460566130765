var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "icon-svg", class: [_vm.className, _vm.mod] },
    [
      _c("svg", { staticClass: "icon-svg__helper" }, [
        _c("use", { attrs: { "xlink:href": _vm.pathName } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }