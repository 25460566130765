import sceneEntry from './entry';
import sceneEntrySide from './entry-side';
import sceneHallLeft1 from './hall-left-1';
import sceneHallLeft2 from './hall-left-2';
import sceneHallRight1 from './hall-right-1';
import sceneHallRight2 from './hall-right-2';
import sceneRoom1 from './room-1';
import sceneRoom2 from './room-2';
import sceneRoom3 from './room-3';
import sceneStreet1 from './street-1';
import sceneStreet2 from './street-2';


export default [
  sceneEntry,
  sceneEntrySide,
  sceneHallLeft1,
  sceneHallLeft2,
  sceneHallRight1,
  sceneHallRight2,
  sceneRoom1,
  sceneRoom2,
  sceneRoom3,
  sceneStreet1,
  sceneStreet2
]