import Scene from 'components/Tour/Scene/Scene';

class Scenes {
  constructor (initialScenes) {
    this.items = {};
  
    initialScenes.forEach(sceneData => this.add(sceneData));
  }
  
  add(sceneData) {
    const scene = new Scene(sceneData.id, sceneData.panoramaImg, {
      sceneOptions: sceneData.params,
      spotSceneTooltipOptions: sceneData.spotSceneTooltipParams,
      spotsScene: sceneData.spotsScene,
      spotsInfo: sceneData.spotsInfo
    });
    
    this.items[sceneData.id] = scene;
  }
  
  getScene(sceneId) {
    return this.items[sceneId];
  }
  
  get formatToPannellum () {
    return Object.keys(this.items).reduce((acc, sceneId) => {
      acc[sceneId] = this.items[sceneId].data;
      return acc;
    }, {});
  }
  
}

export default Scenes;