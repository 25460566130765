var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay-main-nav" }, [
    _vm.isUpperMd
      ? _c(
          "div",
          { staticClass: "overlay-main-nav__close-l" },
          [
            _c("btn-close", {
              nativeOn: {
                click: function($event) {
                  return _vm.clickCloseHandler($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "overlay-main-nav__items" },
      _vm._l(_vm.list, function(item) {
        return _c("li", { staticClass: "overlay-main-nav__item" }, [
          _c(
            "a",
            {
              staticClass: "overlay-main-nav-link",
              class: { "is-active": item.name === _vm.activeName },
              attrs: { href: item.href },
              on: {
                click: function(e) {
                  return _vm.clickLinkHandler(e, item)
                }
              }
            },
            [
              _vm._v(
                "\n                " + _vm._s(item.title) + "\n            "
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }