var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen"
        }
      ],
      staticClass: "overlay-main-tab"
    },
    [
      _c("div", { staticClass: "overlay-main-tab-side" }, [
        _c(
          "div",
          {
            ref: "elSideInnerWr",
            staticClass: "overlay-main-tab-side__inner_wr"
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowBtnBack,
                    expression: "isShowBtnBack"
                  }
                ],
                staticClass: "overlay-main-tab-side__back-l"
              },
              [
                _c("btn-back", {
                  nativeOn: {
                    click: function($event) {
                      return _vm.btnBackClickHandler($event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowBtnClose,
                    expression: "isShowBtnClose"
                  }
                ],
                staticClass: "overlay-main-tab-side__close-l"
              },
              [
                _c("btn-close", {
                  nativeOn: {
                    click: function($event) {
                      return _vm.btnCloseClickHandler($event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "overlay-main-tab-side__inner" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "overlay-main-tab-side__title overlay-main-tab-title"
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c("perfect-scrollbar", [
                  _c(
                    "div",
                    { staticClass: "overlay-main-tab-side__content" },
                    [_vm._t("side-content")],
                    2
                  )
                ])
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.slotContentExists
        ? [
            _c("div", { staticClass: "overlay-main-tab-side _content" }, [
              _c(
                "div",
                {
                  ref: "elSideInnerWrContent",
                  staticClass: "overlay-main-tab-side__inner_wr"
                },
                [
                  _vm.isShowBtnCloseContent
                    ? _c(
                        "div",
                        { staticClass: "overlay-main-tab-side__close-l" },
                        [
                          _c("btn-close", {
                            nativeOn: {
                              click: function($event) {
                                return _vm.btnCloseClickHandler($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "overlay-main-tab-side__inner" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "overlay-main-tab-side__title overlay-main-tab-title"
                        },
                        [_vm._v("Locations")]
                      ),
                      _vm._v(" "),
                      _c("perfect-scrollbar", [
                        _c(
                          "div",
                          { staticClass: "overlay-main-tab-side__content" },
                          [_vm._t("content")],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }