import spotTemplate from './spot.twig';
import {brick} from 'utils/brick';

import svgEntryLeft from 'svg-inline/entry-left.svg';
import svgEntryUp from 'svg-inline/entry-up.svg';
import svgEntryRight from 'svg-inline/entry-right.svg';
import svgEntryDown from 'svg-inline/entry-down.svg';


const iconMap = {
  'left': svgEntryLeft,
  'up': svgEntryUp,
  'right': svgEntryRight,
  'down': svgEntryDown
};

class SpotScene {
  constructor (targetId, params = {}, tooltipParams = {}) {
  
    /**
     * targetId {String} [required] id of destination panorama
     *
     * params {Object} panorama initial options
     *   x {Number} hotspot location in degrees for x axis
     *   y {Number} hotspot location in degrees for y axis
     *   targetX {Number} destination panorama position x axis in degrees
     *   targetY {Number} destination panorama position y axis in degrees
     *
     * tooltipParams {Object} tooltip initial options
     *   imgSrc {String} optional image for spot preview
     *   title {String} optional title for spot content
     *   icon {String} direction of entryIcon, available values: 'left', 'up', 'right', 'down', default: 'up'
     */

    this.targetId = targetId;
    
    this.data = {
      sceneId: targetId,
      pitch: params.y || 0,
      yaw: params.x || 0,
      text: params.text || null,
      type: "scene",
      createTooltipFunc: hotSpotDiv => this._renderTooltip(hotSpotDiv),
      ...params
    };
    
    this.tooltipOptions = tooltipParams;
    
    if(params.targetX) {
      this.data["targetYaw"] = params.targetX;
    }
  
    if(params.targetY) {
      this.data["targetPitch"] = params.targetY;
    }
    
  }
  
  _renderTooltip(hotSpotDiv) {
    const $spotDiv = brick(hotSpotDiv);
    
    const tour = window.tour;
    
    $spotDiv.addClass('spot-scene');
    
    const {tooltipOptions} = this;
  
    const targetScene = tour.getScene(this.targetId);
    const {spotSceneTooltipDefaultOptions: tooltipDefaultOptions} = targetScene;
    
    const options = {
      ...tooltipDefaultOptions,
      ...tooltipOptions
    };
  
    const iconEntry = iconMap[options.iconEntry] || iconMap['up'];
    
    const html = spotTemplate({
      icon: iconEntry,
      title: options.title,
      imgSrc: options.imgSrc
    });
  
    hotSpotDiv.insertAdjacentHTML('beforeend', html);
  }
}

export default SpotScene;