<template>
    <div class="collab-container" v-if="isActive">
            <form>
                <input type="checkbox" checked name="take control" value="a2"> Take control
            </form>
    </div>
</template>

<script>

  export default {

    data () {
      return {
        isActive: true
      };
    },
    computed: {

    },
    props: {

    },
    methods: {

    }
  };
</script>

<style>

    .collab-container{
        position: absolute;
        pointer-events: all;
        right: 0;
        bottom: 0;
        min-height: 200px;
        min-width: 500px;
        padding: 20px;
        color: white;

        background-color: #8c8c8c;
    }


</style>
