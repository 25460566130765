var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.className.root], style: _vm.rootStyle }, [
    _c("div", { ref: "elInner", class: _vm.className.innerEl }, [
      _c("div", { class: _vm.className.textEl }, [
        _vm._v("\n            " + _vm._s(_vm.location) + "\n        ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }