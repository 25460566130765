var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "form-main-input", class: _vm.classList }, [
    !_vm.textarea
      ? _c("input", {
          staticClass: "form-main-input__field",
          attrs: { type: "text", name: _vm.name, placeholder: _vm.placeholder },
          domProps: { value: _vm.value },
          on: { input: _vm.handlerInput }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.textarea
      ? _c("textarea", {
          staticClass: "form-main-input__field",
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.name,
            cols: "30",
            rows: "10"
          },
          domProps: { value: _vm.value }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }