let apiLoaded = false;

import themeDefault from './themes';

const eventMapReady = new CustomEvent('map.ready');

class GoogleMap {
  constructor (id, options = {}) {
    
    this.id = id;
    this.el = document.getElementById(id);
    this.options = options;
    
    this.map = null;
    this.isMapReady = false;
    this.initialized = false;
    
    this.init();
  }
  
  init () {
    if (this.initialized) {
      return;
    }
  
    this.initialized = true;
  
    this._loadApi()
      .then(() => {
        this.map = this._createMap();
      });
  }
  
  addMarker (options) {
    
    const markerData = this._createMarker(options);
    
    if (this.isMapReady) {
      new google.maps.Marker(markerData);
    } else {
      this.el.addEventListener(eventMapReady, () => {
        new google.maps.Marker(markerData);
      });
    }
  }
  
  _createMap () {
    const {id, options} = this;
    
    const map = new google.maps.Map(document.getElementById(id), {
      zoom: options.zoom || 16,
      center: options.center || {lat: -28.643387, lng: 153.612224},
      disableDefaultUI: true,
      draggeble: true,
      styles: options.styles || themeDefault,
      gestureHandling: 'cooperative'
    });
    
    this.isMapReady = true;
    this.el.dispatchEvent(eventMapReady);
    return map;
  }
  
  _createMarker (options) {
    const markerImgSrc = options.markerImg !== undefined ? options.markerImg : paths.resources + 'assets/img/pin.svg';
    
    return {
      position: options.location,
      map: this.map,
      icon: {
        url: markerImgSrc,
        // size: options.markerSize,
        scaledSize: options.markerSize
      }
    };
  }
  
  _loadApi () {
    return new Promise(resolve => {
      if (apiLoaded) {
        return resolve();
      }
      
      const tag = document.createElement('script');
      window.googleMapReady = function () {
        apiLoaded = true;
        resolve();
      };
      tag.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBd89dB-BUz0kmXNkcuiZ6AR8sm4-JCxAQ&callback=googleMapReady';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
  }
}

// export function definePopupClass () {
//   /**
//    * A customized popup on the map.
//    * @param {!google.maps.LatLng} position
//    * @param {!Element} content
//    * @constructor
//    * @extends {google.maps.OverlayView}
//    */
//   var Popup;
//
//   Popup = function (position, content) {
//     this.position = position;
//
//     content.classList.add('popup-map-bubble-content');
//
//     var pixelOffset = document.createElement('div');
//     pixelOffset.classList.add('popup-map-bubble-anchor');
//     pixelOffset.appendChild(content);
//
//     this.anchor = document.createElement('div');
//     this.anchor.classList.add('popup-map-tip-anchor');
//     this.anchor.appendChild(pixelOffset);
//
//     // Optionally stop clicks, etc., from bubbling up to the map.
//     this.stopEventPropagation();
//   };
//   // NOTE: google.maps.OverlayView is only defined once the Maps API has
//   // loaded. That is why Popup is defined inside initMap().
//   Popup.prototype = Object.create(google.maps.OverlayView.prototype);
//
//   /** Called when the popup is added to the map. */
//   Popup.prototype.onAdd = function () {
//     this.getPanes().floatPane.appendChild(this.anchor);
//   };
//
//   /** Called when the popup is removed from the map. */
//   Popup.prototype.onRemove = function () {
//     if (this.anchor.parentElement) {
//       this.anchor.parentElement.removeChild(this.anchor);
//     }
//   };
//
//   /** Called when the popup needs to draw itself. */
//   Popup.prototype.draw = function () {
//     var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
//     // Hide the popup when it is far out of view.
//     var display =
//       Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
//         'block' :
//         'none';
//
//     if (display === 'block') {
//       this.anchor.style.left = divPosition.x + 'px';
//       this.anchor.style.top = divPosition.y - 34 + 'px';
//     }
//     if (this.anchor.style.display !== display) {
//       this.anchor.style.display = display;
//     }
//   };
//
//   /** Stops clicks/drags from bubbling up to the map. */
//   Popup.prototype.stopEventPropagation = function () {
//     var anchor = this.anchor;
//     anchor.style.cursor = 'auto';
//
//     ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart',
//       'pointerdown']
//       .forEach(function (event) {
//         anchor.addEventListener(event, function (e) {
//           e.stopPropagation();
//         });
//       });
//   };
//
//   return Popup;
// }

// let resizeEvt;
//
// $(window).bind('resize.gmap', function () {
//     clearTimeout(resizeEvt);
//     resizeEvt = setTimeout((function () {
//         google.maps.event.trigger(map, 'resize');
//         map.setCenter(uluru);
//     }), 500);
// });

export default GoogleMap;
