import Scenes from 'components/Tour/Scenes/Scenes';

import {setOriesizeTimeout} from 'utils/responsive';
import Collab from './Collab/Collab';


class Tour {
  constructor (id, scenesData = []) {
    
    /**
     * id {String} tour dom container Id for pannellum
     * scenesData {Array}
     */
    
    this.scenes = new Scenes(scenesData);
    
    this.controller = this._createController(id);

    window.cler = this.controller;
    window.tour = this;
    //
    
    setOriesizeTimeout(() => this.resize());
    
    this.onClick((e) => {
      const [y, x] = this.controller.mouseEventToCoords(e);
      
      const sceneName = this.currentSceneName;
      
      console.log('current scene name: ' + sceneName);
      console.log(`x: ${x}, y: ${y}`);
    });

    this.collab = new Collab(this);

  }
  
  _createController (id) {
    return pannellum.viewer(id, {
      showFullscreenCtrl: false,
      showZoomCtrl: false,
      compass: false,
      friction: 0.5,
      default: {
        firstScene: 'street-2',
        sceneFadeDuration: 1000,
        autoLoad: true,
      },
      scenes: this.scenes.formatToPannellum
    });
  }
  
  get currentSceneName () {
    return this.controller.getScene();
  }
  
  getScene (sceneId) {
    return this.scenes.getScene(sceneId);
  }
  
  goTo (sceneId) {
    const currentSceneName = this.currentSceneName;
    
    const sceneExist = this.scenes.getScene(sceneId);
    
    if (sceneExist && currentSceneName !== sceneId) {
      const controller = this.controller;
      controller.loadScene(sceneId);
    }
  }
  
  setX(value) {
    this.controller.setYaw(value);
  }
  
  setY(value) {
    this.controller.setPitch(value);
  }
  
  dispatchSpotInfoOpen(spotData) {
    this.setX(spotData.yaw);
    this.setY(spotData.pitch);
  }
  
  resize() {
    this.controller.resize();
    this.controller.loadScene(this.currentSceneName);
  }
  
  onClick (fn) {
    this.controller.on('mousedown', fn);
  }
  
  onLoadScene(fn) {
    this.controller.on('scenechange', fn);
  }
}

export default Tour;

