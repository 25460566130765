var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay-main-social" }, [
    _c("div", { staticClass: "overlay-main-social" }, [
      _c(
        "ul",
        { staticClass: "overlay-main-social__items" },
        _vm._l(_vm.list, function(item) {
          return _c("li", { staticClass: "overlay-main-social__item" }, [
            _c(
              "a",
              {
                staticClass: "overlay-main-social-link",
                attrs: { href: item.href }
              },
              [
                _c("span", { staticClass: "overlay-main-social-link__text" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }