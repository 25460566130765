var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "overlay-main-form" },
    [
      _c(
        "p",
        { staticClass: "overlay-main-form__title overlay-main-tab-title" },
        [_vm._v("Questions+Comments")]
      ),
      _vm._v(" "),
      _c(
        "form-main-input",
        _vm._b(
          { staticClass: "overlay-main-form__group" },
          "form-main-input",
          _vm.firstName,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "form-main-input",
        _vm._b(
          { staticClass: "overlay-main-form__group" },
          "form-main-input",
          _vm.lastName,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "form-main-input",
        _vm._b(
          { staticClass: "overlay-main-form__group" },
          "form-main-input",
          _vm.email,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "form-main-input",
        _vm._b(
          { staticClass: "overlay-main-form__group" },
          "form-main-input",
          _vm.subject,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "form-main-input",
        _vm._b(
          { staticClass: "overlay-main-form__group", attrs: { textarea: "" } },
          "form-main-input",
          _vm.message,
          false
        )
      ),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overlay-main-form__submit-l" }, [
      _c(
        "button",
        { staticClass: "overlay-main-form-submit", attrs: { type: "submit" } },
        [_vm._v("Send")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }