<template>
    <div class="overlay-main-location">
        <div class="overlay-main-location__info">
            <div class="overlay-main-info">
                <div class="overlay-main-info__item">
                    <span class="overlay-main-info-text">9526 Harding Ave</span>
                </div>
                <div class="overlay-main-info__item">
                    <span class="overlay-main-info-text">Surfside, FL, 33154</span>
                </div>
            </div>
        </div>
        <div id="map" class="overlay-main-location__map"></div>
    </div>
</template>

<script>
    import GoogleMap from 'modules/googleMap';

    export default {
      data() {
        return {
          map: null
        }
      },
      mounted() {
        this.map = new GoogleMap('map', {
          center: {
            lat: 25.789352,
            lng: -80.1398858,
          },
          zoom: 12
        });
      }
    }
</script>