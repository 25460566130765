import panoramaImg from 'images/original/panorama/entry.jpg';
import panoramaImgSmall from 'images/original/panorama-small/entry.jpg';
import spotImgEntry from 'images/spot/entry.jpg';

export default {
  id: 'entry',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -70,
    y: 0
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgEntry,
    title: 'Entry'
  },
  spotsScene: [
    {
      targetId: 'street-2',
      params: {
        x: 110,
        targetX: 180
      }
    },
    {
      targetId: 'entry-side',
      params: {
        x: -160
      }
    },
    {
      targetId: 'hall-left-1',
      params: {
        x: -85
      }
    },
    {
      targetId: 'hall-right-1',
      params: {
        x: -60
      }
    },
    {
      targetId: 'makeup',
      params: {
        x: -127,
        y: -6,
      },
      tooltipParams: {
        iconEntry: 'left'
      }
    }
  ]
}