import './_app-common';

// import 'plugins/jQuery';

import 'modules/browserDetect';

import 'pannellum/src/js/libpannellum';
import 'pannellum/src/js/pannellum';

import 'pannellum/build/pannellum.css';

import 'perfect-scrollbar/css/perfect-scrollbar.css'

import '../sass/main.scss';

import { onReady, onLoad } from 'utils/dom';

import createApp from '../vue/app';

onReady(() => {
  createApp();
});

onLoad(() => {

});