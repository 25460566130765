<template>
    <span class="icon-svg" :class="[className, mod]">
        <svg class="icon-svg__helper">
            <use :xlink:href="pathName"></use>
        </svg>
    </span>
</template>

<script>

  export default {
    data () {
      return {}
    },
    props: {
      dir: String,
      name: {type: String, required: true},
      mod: {type: String},
      type: {
        type: String,
        default: 'single'
      }
    },
    computed: {
      dirName() {
        return this.dir ? this.dir + '--' : '';
      },
      multi() {
        return this.type === 'multi';
      },
      prefix() {
        return this.multi ? '' : 's-';
      },
      className() {
        return `icon-svg_${this.iconName} ${this.multi ? '' : 'icon-svg_s'}`;
      },
      iconName() {
        return this.dirName + this.prefix + this.name;
      },
      pathName() {
        return '/themes/resources/assets/images/sprite.svg#' + this.iconName;
      }
    }
  }
</script>

<style lang="scss">
    .icon-svg__helper {
        width: inherit;
        height: inherit;
        max-width: inherit;
        max-height: inherit;
        font-size: inherit;
    }
</style>