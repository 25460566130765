import PerfectScrollbar from 'perfect-scrollbar';

export default {
  data() {
    return {
      ps: null
    }
  },
  
  props: {
    active: {
      type: Boolean,
      default: true
    },
    default: true
  },
  
  computed: {
    initialized() {
      return this.ps !== null;
    }
  },
  
  methods: {
    scrollToZero() {
      if(!this.ps) {
        return
      }
      this.ps.element.scrollTop = 0;
    },
    
    createPs() {
      if(!this.active || this.initialized) {
        return;
      }
      
      this.ps = new PerfectScrollbar(this.$el, {
        suppressScrollX: true
      });
      
    },
  
    destroyPs() {
      if(!this.initialized) {
        return;
      }
    
      this.ps.destroy();
      this.ps = null;
    },
  },
  
  watch: {
    active(val) {
      if(val === true) {
        this.createPs();
      } else {
        this.destroyPs();
      }
    }
  },
  
  mounted() {
    this.createPs();
  },
  
  beforeDestroy() {
    this.destroyPs();
  },
  
  render() {
    return this.$slots.default;
  }
}