import { render, staticRenderFns } from "./Input.vue?vue&type=template&id=53e12187&"
import script from "./Input.vue?vue&type=script&lang=js&"
export * from "./Input.vue?vue&type=script&lang=js&"
import style0 from "./Input.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "themes/resources/vue/components/FormMain/Input.vue"
export default component.exports