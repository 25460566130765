import SpotScene from 'components/Tour/SpotScene/SpotScene';
import SpotInfo from 'components/Tour/SpotInfo/SpotInfo';

import {DomUtil} from 'utils/dom';

const isIos = window.bowser.isIos;
const deviceOrientation = DomUtil.deviceOrientation;

export default class Scene {
  constructor (id, panoramaImg, params = {}) {
    
    /**
     * id {String} [required] scene id
     * panoramaImg {Object} [required] images for panorama
     *  - original
     *  - small
     *
     * params {Object} panorama initial options
     * - sceneOptions {Object}
     *  - x {Number} panorama starting position x axis in degrees
     *  - y {Number} panorama starting position y axis in degrees
     *
     * - spotSceneTooltipOptions {Object} default settings for spotScene tooltip
     *   - imgSrc {String}
     *   - title {String}
     *
     * - spotsScene {Array}
     * - spotsInfo {Array}
     */
    
    const {
      sceneOptions,
      spotSceneTooltipOptions,
      spotsScene = [],
      spotsInfo = []
    } = params;
    
    // Scene options
    const range = sceneOptions.range || deviceOrientation === 'portrait-primary' ? 80 : 100;
    const rangeMax = sceneOptions.rangeMax || deviceOrientation === 'portrait-primary' ? 80 : 100;
    const x = sceneOptions.x || 0;
    const y = sceneOptions.y || 0;
  
    const img = isIos ? panoramaImg.small : panoramaImg.original;
    
    this.id = id;
    this.img = img;
    
    this.data = {
      panorama: img,
      hfov: range,
      // X coords from -180, 180
      yaw: x,
      maxHfov: rangeMax,
      // Y coords
      pitch: y,
      type: 'equirectangular',
      hotSpots: [],
      ...sceneOptions
    };
    
    this.spotSceneTooltipDefaultOptions = {
      imgSrc: null,
      title: null,
      ...spotSceneTooltipOptions
    };
    
    this.spotsScene = [];
    this.spotsInfo = [];
    
    spotsScene.forEach(spotData => this.addSpotScene(spotData));
    spotsInfo.forEach((spotData => this.addSpotInfo(spotData)));
  }
  
  setX (x) {
    this.data.yaw = x;
  }
  
  setY (y) {
    this.data.pitch = y;
  }
  
  addSpotScene (spotOptions) {
    const id = spotOptions.targetId;
    
    const {params, tooltipParams} = spotOptions;
    
    const spot = new SpotScene(id, params, tooltipParams);
    
    this.spotsScene.push(spot);
    this.data.hotSpots.push(spot.data);
  }
  
  addSpotInfo (spotOptions) {
    const {params, tooltipParams} = spotOptions;
    
    const spot = new SpotInfo(params, tooltipParams);
  
    this.spotsInfo.push(spot);
    this.data.hotSpots.push(spot.data);
  }
}