import {Vue, Router} from './_app-common';

// Vue.config.errorHandler = function (err, vm, info) {
//   alert(err)
// };

// Vue.config.warnHandler = function () {
//   console.log('error');
// }



// const router = new Router({
//   routes: [
//     {path: '/about'}
//   ]
// });

import MixinBreakpoints, {getters as MixinBreakpointsGetters} from './mixins/breakpoints'

import Tour from 'components/Tour/Tour';

import scenesData from 'data/scenes/index';

Vue.mixin(MixinBreakpointsGetters);

export default () => new Vue({
  el: '#page',
  data() {
    return {
      mounted: false
    }
  },
  mixins: [MixinBreakpoints],
  mounted() {
    const tour = new Tour('panorama', scenesData);
    Vue.prototype.$tour = tour;
    
    this.mounted = true;
  }
});
