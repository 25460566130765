import Vue from 'vue';

import OverlayMain from 'vue-components/OverlayMain/OverlayMain';

import SvgIcon from 'vue-utils/svg';

import FormMainInput from 'vue-components/FormMain/Input';

import Router from 'vue-router';

import ModulePs from './modules/ps';

Vue.use(Router);

Vue.component('perfect-scrollbar', ModulePs);

Vue.component('form-main-input', FormMainInput);
Vue.component('overlay-main', OverlayMain);
Vue.component('svg-icon', SvgIcon);

export {Vue, Router};