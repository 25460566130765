import Pusher from 'pusher-js';
import Axios from 'axios';

class Collab {

    constructor(tourRef){

        this.initPusher();
        this.userId = Math.random().toString(36).substr(2, 9);
        this.tour = tourRef;

        this.tour.controller.on('animatefinished', (data)=>{
            if(!data['is_following']){
                this.sendMyMoves(data)
            }
        });

        this.tour.controller.on('animationProgress', (data)=>{
            if(!data['is_following']) {
                this.sendMyMoves(data)
            }
        });


        this.tour.controller.on('mouseMoveThrottled', (data)=>{
            //console.log('mouseMoveThrottled data="', data, '"');

            //this.renderCollabUserPointer({...data, userId: this.userId});
            this.sendMyMoves(data, 'pointer')

        });


        this.collaborators = [
            {
                userId: 11111,
                pointer: {
                    pitch: 0,
                    yaw: 0
                }
            }

        ];

        //this.createUserPointer(this.userId, '#c1ff25');


    }


    initPusher(){

        let pusher = new Pusher('84492b66a5a280726363', {
            cluster: 'ap2',
            forceTLS: true
        });

        this.channel = pusher.subscribe('collab-channel');
        this.channel.bind('move', (data) => this.reflectCollabMoving(data));
        this.channel.bind('pointer', (data) => this.renderCollabUserPointer(data));


    }

    reflectCollabMoving(data){

        const reflectParams = JSON.parse(data);
        //console.log('recieved moving: ', data);

        if(this.userId !== reflectParams['userId']) {

            if(this.tour.controller.getScene() !== reflectParams['currentScene']){
                this.tour.controller.loadScene(reflectParams['currentScene'], reflectParams['pitch'], reflectParams['yaw']);
            }


            this.tour.controller.isFollowing = true;
            this.tour.controller.setPitch(reflectParams['pitch'], 1000);
            this.tour.controller.setYaw(reflectParams['yaw'], 1000);
        }

    }

    sendMyMoves(data, eventName='move'){

        const currentScene = this.tour.controller.getScene();

        data = {...data, userId: this.userId, currentScene};

        let params = new URLSearchParams();
        params.append('data', JSON.stringify(data));
        params.append('event', eventName);

        Axios.post('/pusher.php', params);

    }

    trackUserPointer(data){

    }

    createUserPointer(userId, color, data){

        this.tour.controller.addHotSpot({
            "userId": userId,
            "pitch": 10,
            "yaw": 10,
            "cssClass": "user-hotspot ",
            "createTooltipFunc": userHotspot,
            "createTooltipArgs": color
        });

    }

    renderCollabUserPointer(data){

        const pointerParams = JSON.parse(data);
        console.log('recieved pointer: ', data);

        const collabUserId = pointerParams['userId'];

        if(this.userId !== collabUserId) {

            const userPointerHS = this.tour.controller.getConfig().hotSpots.filter(hs => hs.userId === collabUserId)[0];
            if (!userPointerHS) {
                this.createUserPointer(collabUserId, '#' + Math.floor(Math.random() * 16777215).toString(16), data);
            }else{
                userPointerHS.yaw = pointerParams['yaw'];
                userPointerHS.pitch = pointerParams['pitch'];
                this.tour.controller.renderHotSpots();
            }
        }

    }



}

export default Collab;

// User hot spot creation function
function userHotspot(hotSpotDiv, args) {
    hotSpotDiv.classList.add('custom-tooltip');
    hotSpotDiv.style.backgroundColor = args;
}