var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "overlay-main",
      class: _vm.classList,
      attrs: { id: "overlayMain" }
    },
    [
      _vm.isBelowMd
        ? _c(
            "div",
            { staticClass: "overlay-main__back-l" },
            [
              _c("btn-back", {
                attrs: { small: "" },
                nativeOn: {
                  click: function($event) {
                    return _vm.btnBackClickHandler($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isBelowMd
        ? _c(
            "div",
            { staticClass: "overlay-main__close-l" },
            [
              _c("btn-close", {
                attrs: { small: "" },
                nativeOn: {
                  click: function($event) {
                    return _vm.btnCloseClickHandler($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "overlay-main__toggle-l" },
        [
          _c("btn-toggle", {
            nativeOn: {
              click: function($event) {
                return _vm.openNav($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isNavShow,
              expression: "isNavShow"
            }
          ],
          staticClass: "overlay-main__nav-l"
        },
        [
          _c("Nav", {
            ref: "vcNav",
            attrs: { list: _vm.navList, activeName: _vm.tabActiveName },
            on: {
              btnCloseClick: _vm.navBtnCloseClickHandler,
              linkClick: _vm.navLinkClickHandler
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overlay-main__inner" }, [
        _vm.isCurrentLocationEnable
          ? _c(
              "div",
              { staticClass: "overlay-main__current-location-l" },
              [
                _c("current-location", {
                  attrs: { location: _vm.sceneActiveName }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "overlay-main__header-l" }, [
          _c("div", { staticClass: "overlay-main-border _horizontal _grow" }),
          _vm._v(" "),
          _c("div", { staticClass: "overlay-main-content-t1 _up" }, [
            _c(
              "h1",
              {
                staticClass: "overlay-main-title",
                on: { click: _vm.titleClickHandler }
              },
              [
                _c("span", { staticClass: "overlay-main-title__text" }, [
                  _vm._v("Esmell Miami")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "overlay-main-border _horizontal _grow" })
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay-main-border _vertical _left _absolute"
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay-main-border _vertical _right _absolute"
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowContentLayout,
                expression: "isShowContentLayout"
              }
            ],
            staticClass: "overlay-main__content-l"
          },
          [
            _c(
              "div",
              { staticClass: "overlay-main__content" },
              [
                _vm.isUpperMd
                  ? _c(
                      "Tabs",
                      {
                        key: "tabs-1",
                        ref: "vcTabs",
                        staticClass: "overlay-main__tabs",
                        on: { tabNameChange: _vm.tabNameChangeHandler }
                      },
                      [
                        _c("tab", {
                          ref: "vcTabServices",
                          staticClass: "_services",
                          attrs: {
                            name: "services",
                            "is-show-btn-back": _vm.isTabServicesShowBtnBack,
                            title: "Services"
                          },
                          on: {
                            btnBackClick: _vm.tabServicesBtnBackClickHandler,
                            btnCloseClick: _vm.tabBtnCloseClickHandler
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "side-content",
                                fn: function() {
                                  return [
                                    _c("Services", {
                                      ref: "vcServices",
                                      attrs: { list: _vm.services },
                                      on: {
                                        serviceClick: _vm.serviceClickHandler,
                                        toggleShowContent:
                                          _vm.servicesToggleShowContentHandler
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2320975414
                          )
                        }),
                        _vm._v(" "),
                        _c("tab", {
                          ref: "vcTabAbout",
                          attrs: { name: "about", title: "About us" },
                          on: { btnCloseClick: _vm.tabBtnCloseClickHandler },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "side-content",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "overlay-main-text" },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                    It is with outmost joy and care for details that we begin our extensive and\n                                    exquisite\n                                    list of services in south Florida. For 20 years redefining beauty and care in\n                                    Brazil, we\n                                    bring to Miami our latest vision, inspired by the life style of it’s people,\n                                    innovating\n                                    costumer service, utilizing the best product lines and technology.\n                                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "\n                                    In the heart of every strong tradition, you will find dedication and experience.\n                                "
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            316878439
                          )
                        }),
                        _vm._v(" "),
                        _c("tab", {
                          ref: "vcTabContact",
                          attrs: { name: "contact", title: "Contact" },
                          on: { btnCloseClick: _vm.tabBtnCloseClickHandler },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "side-content",
                                fn: function() {
                                  return [_c("contact")]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [_c("location")]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3943814498
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isBelowMd
                  ? _c(
                      "Tabs",
                      {
                        key: "tabs-2",
                        ref: "vcTabs",
                        staticClass: "overlay-main__tabs",
                        on: { tabNameChange: _vm.tabNameChangeHandler }
                      },
                      [
                        _c(
                          "tab-mobile",
                          { attrs: { title: "About us", name: "about" } },
                          [
                            _c("div", { staticClass: "overlay-main-text" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                                It is with outmost joy and care for details that we begin our extensive and\n                                exquisite\n                                list of services in south Florida. For 20 years redefining beauty and care in\n                                Brazil, we\n                                bring to Miami our latest vision, inspired by the life style of it’s people,\n                                innovating\n                                costumer service, utilizing the best product lines and technology.\n                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                                In the heart of every strong tradition, you will find dedication and experience.\n                            "
                                )
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tab-mobile",
                          {
                            staticClass: "_services",
                            attrs: { title: "Services", name: "services" }
                          },
                          [
                            _c("Services", {
                              ref: "vcServices",
                              attrs: { list: _vm.services },
                              on: {
                                serviceClick: _vm.serviceClickHandler,
                                goToLocationClick:
                                  _vm.servicesGoToLocationClickHandler,
                                toggleShowContent:
                                  _vm.servicesToggleShowContentHandler
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "tab-mobile",
                          { attrs: { title: "Contact", name: "contact" } },
                          [_c("contact"), _vm._v(" "), _c("location")],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "overlay-main__footer-l" }, [
          _c("div", { staticClass: "overlay-main-border _horizontal _grow" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "overlay-main-content-t1 _down" },
            [_c("Social")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "overlay-main-border _horizontal _grow" })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }