var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen"
        }
      ],
      staticClass: "overlay-main-tab-mobile"
    },
    [
      _c(
        "p",
        {
          staticClass: "overlay-main-tab-mobile__title overlay-main-tab-title"
        },
        [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
      ),
      _vm._v(" "),
      _c("perfect-scrollbar", [
        _c(
          "div",
          { staticClass: "overlay-main-tab-mobile__content" },
          [_vm._t("default")],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }