<template>
    <div class="overlay-main-social">
        <div class="overlay-main-social">
            <ul class="overlay-main-social__items">
                <li v-for="item in list" class="overlay-main-social__item">
                    <a v-bind:href="item.href" class="overlay-main-social-link">
                        <span class="overlay-main-social-link__text">{{item.title}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          list: [
            {href: '/', title: 'Instagram'},
            {href: '/', title: 'Facebook'}
          ]
        }
      }
    }
</script>