import panoramaImg from 'images/original/panorama/room-2.jpg';
import panoramaImgSmall from 'images/original/panorama-small/room-2.jpg';
import spotImgRoom2 from 'images/spot/room-2.jpg';

export default {
  id: 'room-2',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -170
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgRoom2,
    title: 'room 2'
  },
  spotsScene: [
    {
      targetId: 'hall-left-1',
      params: {
        x: -10,
        targetX: -90
      }
    }
  ]
}