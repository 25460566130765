import { TweenLite } from 'gsap';

export default {
  methods: {
    animateInnerWrOpen () {
      
      const {$refs: {elSideInnerWr, elSideInnerWrContent}} = this;
      
      const elems = [elSideInnerWr, elSideInnerWrContent].filter(el => el !== undefined);
  
      const delay = 0.5;
  
      return new Promise(resolve => {
        
        elems.forEach((el, i) => {
          TweenLite.fromTo(el, 0.5, {
            x: el.offsetWidth * -1
          }, {
            x: 0,
            delay: i * delay,
            onComplete: () => {
              if (i === elems.length - 1) {
                resolve();
              }
            }
          });
        });
      });
    },
    animateInnerWrClose () {
      const {$refs: {elSideInnerWr, elSideInnerWrContent}} = this;
    
      let elems = [elSideInnerWr, elSideInnerWrContent].filter(el => el !== undefined).reverse();
      
      const delay = 0.5;
  
      return new Promise(resolve => {
        elems.forEach((el, i) => {
          TweenLite.fromTo(el, 0.5, {
            x: 0
          }, {
            x: el.offsetWidth * -1,
            clearProps: 'x',
            delay: i * delay,
            onComplete: () => {
              if (i === elems.length - 1) {
                resolve();
              }
            }
          });
        });
      });
    }
  }
}