import imgTest from 'images/pics/service-test.jpg';

export default {
  services: [
    {
      title: 'HairStyles',
      imgSrc: imgTest,
      sceneId: 'hall-left-1',
    },
    {
      title: 'HairCut',
      imgSrc: imgTest,
      sceneId: 'haircut'
    },
    {
      title: 'Makeup',
      imgSrc: imgTest,
      sceneId: 'makeup'
    },
    {
      title: 'Barbershop',
      imgSrc: imgTest,
      sceneId: 'barbershop'
    }
  ]
}