const isProd = NODE_ENV === 'production';

function addClass (elem, classname) {
  if(!elem) return;
  
  if (classname) {
    if (elem.classList) elem.classList.add(classname);
    else elem.className += ' ' + classname;
  }
}

function removeClass (elem, classname) {
  if(!elem) return;
  
  if (classname) {
    if (elem.classList) elem.classList.remove(classname);
    else elem.className = elem.className.replace(new RegExp('(^|\\b)' + classname.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }
}

function toggleClass(elem, classname) {
  if(!elem) return;
  
  if (elem.classList) {
    elem.classList.toggle(classname);
  } else {
    const classes = el.className.split(' ');
    const existingIndex = classes.indexOf(classname);
    
    if (existingIndex >= 0)
      classes.splice(existingIndex, 1);
    else
      classes.push(classname);
    
    el.className = classes.join(' ');
  }
}

function hasClass(elem, classname) {
  if(!elem) return false;
  return (' ' + elem.className + ' ').indexOf(' ' + classname + ' ') > -1;
}

function getElemByClass (classname, instance = document) {
  
  if (!instance) {
    return;
  }
  
  return instance.querySelector(`.${classname}`);
}

function getElemsByClass (classname, instance = document) {
  if (!instance) {
    return;
  }
  const collection = instance.getElementsByClassName(classname);
  
  return Array.prototype.slice.call(collection);
}

function getElemById (id, instance = document) {
  if (!instance) return;
  
  return instance.getElementById(id);
}

function getElemByQeury(selector, instance = document) {
  if (!instance) return;
  
  return instance.querySelector(selector);
}

function getElemsByQuery (selector, instance = document) {
  if (!instance) return;
  
  return instance.querySelectorAll(selector);
}

function getClosestElem (elem, selector) {
  let matchesSelector = elem.matches || elem.webkitMatchesSelector || elem.mozMatchesSelector || elem.msMatchesSelector;
  
  while (elem) {
    if (matchesSelector.call(elem, selector)) {
      break;
    }
    elem = elem.parentElement;
  }
  return elem;
}

function getElemOffset (elem) {
  let rect = elem.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
}

function isAnyPartOfElemInViewport (elem) {
  const rect = elem.getBoundingClientRect();
  
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
  
  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
  
  return (vertInView && horInView);
}

function onReady (fn) {
  if (isProd) {
    document.addEventListener('DOMContentLoaded', function (e) {
      fn();
    });
  } else {
    window.addEventListener('load', function () {
      fn();
    });
  }
}

function onLoad (fn) {
  window.addEventListener('load', function () {
    fn();
  });
}

function resetElemStyles (elem, styles = []) {
  styles.forEach(styleName => {
    elem.style[styleName] = '';
  });
}

class DomUtil {
  
  static get windowWidth () {return Math.min(document.documentElement.clientWidth, window.innerWidth || 0);}
  
  static get windowHeight () {return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);}
  
  static get deviceOrientation () {
    let orientation = screen.msOrientation || (screen.orientation || screen.mozOrientation || {}).type;
    
    if (!orientation) {
      orientation = window.orientation === 180 || window.orientation === 0 ? 'portrait-primary' : 'landscape-primary';
    }
    
    return orientation;
  }
}

export {
  DomUtil,
  addClass,
  removeClass,
  hasClass,
  toggleClass,
  resetElemStyles,
  onReady,
  onLoad,
  isAnyPartOfElemInViewport,
  getClosestElem,
  getElemByClass,
  getElemById,
  getElemByQeury,
  getElemOffset,
  getElemsByClass,
  getElemsByQuery,
};

