import panoramaImg from 'images/original/panorama/hall-left-part-1.jpg';
import panoramaImgSmall from 'images/original/panorama-small/hall-left-part-1.jpg';
import spotImgHallLeft1 from 'images/spot/hall-left-1.jpg';

export default  {
  id: 'hall-left-1',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: 180
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgHallLeft1,
    title: 'hall left 1'
  },
  spotsScene: [
    {
      targetId: 'entry',
      params: {
        x: -10,
        targetX: 110
      }
    },
    {
      targetId: 'makeup',
      params: {
        x: 17.5
      },
      tooltipParams: {
        iconEntry: 'right'
      }
    },
    {
      targetId: 'room-2',
      params: {
        x: 75
      },
      tooltipParams: {
        iconEntry: 'left'
      }
    },
    {
      targetId: 'room-3',
      params: {
        x: 120
      },
      tooltipParams: {
        iconEntry: 'left'
      }
    },
    {
      targetId: 'barbershop',
      params: {
        x: 163,
        y: -16
      }
    }
  ],
  spotsInfo: [
    {
      params: {
        x: -140,
        y: -22
      },
      tooltipParams: {
        icon: 'dryer',
        title: 'hairstyles',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    }
  ]
}