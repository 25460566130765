import panoramaImg from 'images/original/panorama/entry-side.jpg';
import panoramaImgSmall from 'images/original/panorama-small/entry-side.jpg';
import spotImgEntrySide from 'images/spot/entry-side.jpg';

export default {
  id: 'entry-side',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -85
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgEntrySide,
    title: 'Entry side'
  },
  spotsScene: [
    {
      targetId: 'entry',
      params: {
        x: 90,
        targetX: 20
      }
    }
  ],
  spotsInfo: [
    {
      params: {
        x: -67,
        y: 7
      },
      tooltipParams: {
        icon: 'camera',
        title: 'camera',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    },
    {
      params: {
        x: -27,
        y: -7
      },
      tooltipParams: {
        icon: 'cosmetics',
        title: 'cosmetics',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    }
  ]
};