// if(module.hot) {
//   module.hot.accept('./common/navbar', function () {
//     require('./common/navbar');
//   });
// }

const imagesContext = require.context('images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

import 'custom-event-polyfill';

// import 'imports-loader?define=>false!<module-name>'
const twig = require('twig').twig;
import svgTemplate from 'utils/svg.twig'

twig({
  id: 'svg-icon',
  data: svgTemplate.tokens,
  allowInlineIncludes: true,
  rethrow: true
});

// import objectFitImages from 'object-fit-images';
// import lazyLoad from 'vanilla-lazyload';

// const myLazyLoad = new lazyLoad({
//   elements_selector: ".lazy img"
// });

// const example = () => {
//   return import(/* webpackChunkName: "carousel" */ './components/component/index.js');
// };