var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay-main-contact" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "overlay-main-contact__form-l" },
      [_c("v-form")],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "overlay-main-contact__info overlay-main-info" },
      [
        _c("div", { staticClass: "row overlay-main-info-group" }, [
          _c(
            "div",
            { staticClass: "col-12 col-xl-6 overlay-main-info-group__col" },
            [
              _c("div", { staticClass: "overlay-main-info__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "overlay-main-info-link",
                    attrs: { href: "tel:+17868642900" }
                  },
                  [_vm._v("+ 1 786 864 2900")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "overlay-main-info__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "overlay-main-info-link",
                    attrs: { href: "mailto:contact@esmell.us" }
                  },
                  [_vm._v("contact@esmell.us")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-6 overlay-main-info-group__col" }, [
            _c("div", { staticClass: "overlay-main-info__item" }, [
              _c("span", { staticClass: "overlay-main-info-text" }, [
                _vm._v("Mon : 10am – 6pm")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "overlay-main-info__item" }, [
              _c("span", { staticClass: "overlay-main-info-text" }, [
                _vm._v("Teu - Sat : 9am – 9pm")
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }