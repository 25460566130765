import panoramaImg from 'images/original/panorama/hall-left-part-2.jpg';
import panoramaImgSmall from 'images/original/panorama-small/hall-left-part-2.jpg';
import spotImgHallLeft2 from 'images/spot/hall-left-2.jpg';

export default {
  id: 'barbershop',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -180
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgHallLeft2,
    title: 'barbershop'
  },
  spotsScene: [
    {
      targetId: 'hall-left-1',
      params: {
        x: 0,
        targetX: 1
      }
    }
  ],
  spotsInfo: [
    {
      params: {
        x: -155,
        y: -4
      },
      tooltipParams: {
        icon: 'nails',
        title: 'nails',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    },
    {
      params: {
        x: 131,
        y: -9
      },
      tooltipParams: {
        icon: 'wash',
        title: 'wash',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    }
  ]
}