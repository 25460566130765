<template>
    <button :class="cn.root">
        <span :class="cn.inner">
            <span :class="cn.sep"></span>
            <span :class="cn.sep"></span>
            <span :class="cn.sep"></span>
        </span>
    </button>
</template>

<script>
    export default {
      data() {
        return {
          className: 'overlay-main-btn-toggle'
        }
      },
      computed: {
        cn() {
          const className = this.className;
          return {
            root: className,
            inner: className + '__inner',
            sep: className + '__sep'
          }
        }
      }
    }
</script>