import { DomUtil } from 'utils/dom';

import { breakpoints } from 'utils/breakpoints';
import {setOriesizeTimeout} from 'utils/responsive';


export default {
  data () {
    return {
      windowWidth: DomUtil.windowWidth
    }
  },
  
  mounted () {
    setOriesizeTimeout(() => {
      this.windowWidth = DomUtil.windowWidth;
    });
  }
};

export const getters = {
  computed: {
    isUpperMd() {
      this.$root.windowWidth;
      return breakpoints.isUpperMd;
    },
    
    isUpperXl() {
      this.$root.windowWidth;
      return breakpoints.isUpperXl
    },
    
    isBelowMd() {
      this.$root.windowWidth;
      return breakpoints.isBelowMd;
    },
    
    isBelowXl() {
      this.$root.windowWidth;
      return breakpoints.isBelowXl
    }
  }
};