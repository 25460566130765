<template>
    <label class="form-main-input" v-bind:class="classList">
        <input v-if="!textarea" class="form-main-input__field"
            type="text"
            v-bind:name="name"
            v-bind:value="value"
            v-bind:placeholder="placeholder"
            v-on:input="handlerInput"/>
        <textarea
            class="form-main-input__field"
            v-if="textarea"
            v-bind:placeholder="placeholder"
            v-bind:name="name"
            v-bind:value="value"
            cols="30" rows="10">
        </textarea>
    </label>
</template>

<script>
    export default {
      props: {
        value: String,
        name: String,
        placeholder: String,
        textarea: {
          default: false,
          type: Boolean
        }
      },
      computed: {
        classList() {
          return {
            '_textarea': this.textarea === true
          }
        }
      },
      methods: {
        handlerInput() {
          this.$emit('input', this.value);
        }
      }
    }
</script>

<style lang="scss">
    .form-main-input {
        display: block;
        width: 100%;
        &__field {
            padding-left: 20px;
            padding-right: 20px;
            font-size: 18px;
            line-height: 22px;
            color: #C09891;
            width: 100%;
            height: 50px;
            display: block;
            background-color: #FFFBF9;
            border: none;
            border-radius: 0;
            @include placeholder($color: #C09891);

        }

        &._textarea &__field {
            resize: none;
            padding-top: 14px;
            padding-bottom: 14px;
            height: 150px;
        }
    }
</style>