<template>
    <div v-show="isOpen" class="overlay-main-tab-mobile">
        <p class="overlay-main-tab-mobile__title overlay-main-tab-title">
            {{title}}
        </p>
        <perfect-scrollbar>
            <div class="overlay-main-tab-mobile__content">
                <slot/>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
  import { TweenLite } from 'gsap';

  export default {

    data () {
      return {
        isOpen: false
      };
    },
    props: {
      name: String,
      title: String,
    },
    methods: {
      open () {

        return new Promise(resolve => {
          this.isOpen = true;

          resolve();
        });

      },
      close () {
        return new Promise(resolve => {
          this.isOpen = false;
          this.$emit('close');
          resolve();
        });
      },
      toggle () {
        this.isOpen ? this.close() : this.open();
      }
    }
  };
</script>