import panoramaImg from 'images/original/panorama/street-2.jpg';
import panoramaImgSmall from 'images/original/panorama-small/street-2.jpg';
import spotImgStreet2 from 'images/spot/street-2.jpg'

export default {
  id: 'street-2',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {},
  spotSceneTooltipParams: {
    imgSrc: spotImgStreet2,
    title: 'street 2'
  },
  spotsScene: [
    {
      targetId: 'entry',
      tooltipParams: {
        iconEntry: 'left'
      }
    },
    {
      targetId: 'street-1',
      params: {
        x: 170,
        targetX: 5
      }
    }
  ],
  spotsInfo: [
    {
      params: {
        x: 123,
        y: -23
      },
      tooltipParams: {
        icon: 'parking',
        title: 'parking',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    },
    {
      params: {
        x: -37,
        y: -6
      },
      tooltipParams: {
        icon: 'info',
        title: 'info',
        text: '<p>A hairstyle refers to the styling of hair, usually on the human scalp. Sometimes, this could also mean an editing of facial or body hair.</p>'
      }
    }
  ]
};