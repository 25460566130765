var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlay-main-services" },
    [
      _c("perfect-scrollbar", [
        _c(
          "ul",
          { ref: "elItems", staticClass: "overlay-main-services__items" },
          _vm._l(_vm.list, function(item) {
            return _c("li", { staticClass: "overlay-main-services__item" }, [
              _c(
                "a",
                {
                  staticClass: "overlay-main-service",
                  attrs: { href: "/" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function(e) {
                        return _vm.serviceClickHandler(e, item)
                      })($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "overlay-main-service__pic-l" }, [
                    _c("div", { staticClass: "overlay-main-service-pic" }, [
                      _c("img", {
                        staticClass: "overlay-main-service-pic__img",
                        attrs: { src: item.imgSrc, alt: "" }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "overlay-main-service__text-l" }, [
                    _c("p", { staticClass: "overlay-main-service-text" }, [
                      _vm._v(_vm._s(item.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "overlay-main-service__arrow-l" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "arrow-left-long",
                          mod: "overlay-main-service-arrow"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "elContent", staticClass: "overlay-main-services__content" },
        [
          _c("perfect-scrollbar", [
            _c("div", {
              staticClass: "overlay-main-services__text overlay-main-text",
              domProps: { innerHTML: _vm._s(_vm.activeText) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "overlay-main-services__btn-l" }, [
            _c(
              "button",
              {
                staticClass: "overlay-main-services-btn",
                on: { click: _vm.goToLocationClickHandler }
              },
              [
                _c("span", { staticClass: "overlay-main-services-btn__text" }, [
                  _vm._v(
                    "\n                    Go to location\n                "
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }