<template>
    <main id="overlayMain" class="overlay-main" v-bind:class="classList">
        <div v-if="isBelowMd" class="overlay-main__back-l">
            <btn-back v-on:click.native="btnBackClickHandler" small/>
        </div>
        <div v-if="isBelowMd" class="overlay-main__close-l">
            <btn-close v-on:click.native="btnCloseClickHandler" small/>
        </div>
        <div class="overlay-main__toggle-l">
            <btn-toggle v-on:click.native="openNav"/>
        </div>
        <div v-show="isNavShow" class="overlay-main__nav-l">
            <Nav
                ref="vcNav"
                v-on:btnCloseClick="navBtnCloseClickHandler"
                v-on:linkClick="navLinkClickHandler"
                v-bind:list="navList"
                v-bind:activeName="tabActiveName"
            />
        </div>
        <div class="overlay-main__inner">
            <!--<Collab></Collab>-->
            <div v-if="isCurrentLocationEnable" class="overlay-main__current-location-l">
                <current-location v-bind:location="sceneActiveName"/>
            </div>
            <div class="overlay-main__header-l">
                <div class="overlay-main-border _horizontal _grow"></div>
                <div class="overlay-main-content-t1 _up">
                    <h1 v-on:click="titleClickHandler" class="overlay-main-title">
                        <span class="overlay-main-title__text">Esmell Miami</span>
                    </h1>
                </div>
                <div class="overlay-main-border _horizontal _grow"></div>
            </div>
            <div class="overlay-main-border _vertical _left _absolute"></div>
            <div class="overlay-main-border _vertical _right _absolute"></div>
            <div v-show="isShowContentLayout" class="overlay-main__content-l">
                <div class="overlay-main__content">
                    <Tabs
                        key="tabs-1"
                        v-if="isUpperMd"
                        ref="vcTabs"
                        class="overlay-main__tabs"
                        v-on:tabNameChange="tabNameChangeHandler">
                        <tab
                            class="_services"
                            ref="vcTabServices"
                            name="services"
                            v-on:btnBackClick="tabServicesBtnBackClickHandler"
                            v-on:btnCloseClick="tabBtnCloseClickHandler"
                            v-bind:is-show-btn-back="isTabServicesShowBtnBack"
                            title="Services">
                            <template v-slot:side-content>
                                <Services
                                    ref="vcServices"
                                    v-on:serviceClick="serviceClickHandler"
                                    v-bind:list="services"
                                    v-on:toggleShowContent="servicesToggleShowContentHandler"
                                />
                            </template>
                        </tab>
                        <tab ref="vcTabAbout"
                             name="about"
                             v-on:btnCloseClick="tabBtnCloseClickHandler"
                             title="About us">
                            <template v-slot:side-content>
                                <div class="overlay-main-text">
                                    <p>
                                        It is with outmost joy and care for details that we begin our extensive and
                                        exquisite
                                        list of services in south Florida. For 20 years redefining beauty and care in
                                        Brazil, we
                                        bring to Miami our latest vision, inspired by the life style of it’s people,
                                        innovating
                                        costumer service, utilizing the best product lines and technology.
                                    </p>
                                    <p>
                                        In the heart of every strong tradition, you will find dedication and experience.
                                    </p>
                                </div>
                            </template>
                        </tab>
                        <tab ref="vcTabContact"
                             name="contact"
                             v-on:btnCloseClick="tabBtnCloseClickHandler"
                             title="Contact">
                            <template v-slot:side-content>
                                <contact/>
                            </template>
                            <template v-slot:content>
                                <location/>
                            </template>
                        </tab>
                    </Tabs>
                    <Tabs
                        key="tabs-2"
                        v-if="isBelowMd"
                        ref="vcTabs"
                        class="overlay-main__tabs"
                        v-on:tabNameChange="tabNameChangeHandler"
                    >
                        <tab-mobile
                            title="About us"
                            name="about">
                            <div class="overlay-main-text">
                                <p>
                                    It is with outmost joy and care for details that we begin our extensive and
                                    exquisite
                                    list of services in south Florida. For 20 years redefining beauty and care in
                                    Brazil, we
                                    bring to Miami our latest vision, inspired by the life style of it’s people,
                                    innovating
                                    costumer service, utilizing the best product lines and technology.
                                </p>
                                <p>
                                    In the heart of every strong tradition, you will find dedication and experience.
                                </p>
                            </div>
                        </tab-mobile>
                        <tab-mobile
                            title="Services"
                            name="services"
                            class="_services"
                            >
                            <Services
                                ref="vcServices"
                                v-on:serviceClick="serviceClickHandler"
                                v-on:goToLocationClick="servicesGoToLocationClickHandler"
                                v-on:toggleShowContent="servicesToggleShowContentHandler"
                                v-bind:list="services"
                            />
                        </tab-mobile>
                        <tab-mobile
                            title="Contact"
                            name="contact">
                            <contact/>
                            <location/>
                        </tab-mobile>
                    </Tabs>
                </div>
            </div>
            <div class="overlay-main__footer-l">
                <div class="overlay-main-border _horizontal _grow"></div>
                <div class="overlay-main-content-t1 _down">
                    <Social/>
                </div>
                <div class="overlay-main-border _horizontal _grow"></div>
            </div>
        </div>
    </main>
</template>

<script>
  import Nav from './nav';
  import Services from './services';
  import Social from './social';
  import Tabs from './tab/Tabs';
  import Tab from './tab/Tab';
  import TabMobile from './tab/Tab-mobile';
  import Location from './location';
  import Contact from './contact/Contact';

  import CurrentLocation from './currentLocation';

  import data from 'data';

  import BtnToggle from './btnToggle';
  import BtnBack from './btnBack';
  import BtnClose from './btnClose';

  import Collab from './collab';

  import { setOriesizeTimeout } from 'utils/responsive';

  export default {
    data () {
      return {
        sceneActiveName: null,
        tabActiveName: null,
        navList: [
          {title: 'About us', href: '/about', name: 'about'},
          {title: 'Services', href: '/services', name: 'services'},
          {title: 'Contact', href: '/contact', name: 'contact'}
        ],
        isServicesShowContent: null,
        services: data.services,
        isNavOpen: false,
        isMounted: false
      };
    },
    components: {
      Services,
      Nav,
      Social,
      Tabs,
      Tab,
      TabMobile,
      Contact,
      Location,
      BtnBack,
      BtnClose,
      BtnToggle,
      CurrentLocation,
      Collab
    },
    computed: {
      isCurrentLocationEnable() {
        return this.isBelowMd;
      },

      isShowContentLayout() {
        return this.tabActiveName !== null
      },

      isNavShow () {
        const {isBelowXl} = this;

        if (isBelowXl) {
          return this.isNavOpen;
        }

        return true;
      },
      classList () {
        const {isNavShow} = this;

        const {isUpperMd, isBelowMd} = this;

        return {
          'is-tab-active': this.tabActiveName !== null,
          'is-push-content': isUpperMd ? this.tabActiveName === 'contact' : false,
          'is-mobile-nav-active': isBelowMd && isNavShow
        };
      },
      isTabServicesShowBtnBack () {
        const {isMounted, isBelowMd} = this;

        if (!isMounted || isBelowMd) {
          return false;
        }

        return this.isServicesShowContent;
      }
    },
    methods: {
      tabNameChangeHandler(name) {
        this.tabActiveName = name;
      },

      btnBackClickHandler () {
        const {tabActiveName, $refs: {vcServices}} = this;

        if (!tabActiveName) {
          return;
        }

        if (this.isServicesShowContent) {
          vcServices.hideContent();
        } else {
          this.closeActiveTab();
        }

      },

      btnCloseClickHandler () {
        const {tabActiveName, $refs: {vcServices}} = this;

        if (tabActiveName === 'services') {
          vcServices.hideContent();
        }

        this.close();
      },

      tabServicesBtnBackClickHandler () {
        const {$refs: {vcServices}} = this;
        vcServices.hideContent();
      },

      tabBtnCloseClickHandler () {
        this.closeForce();
      },

      navBtnCloseClickHandler () {
        this.closeNav();
      },

      openNav () {
        this.isNavOpen = true;
      },

      closeNav () {
        this.isNavOpen = false;
      },

      closeActiveTab () {
        const {$refs: {vcTabs}} = this;

        return new Promise(resolve => {
          const {tabActiveName} = this;

          if(tabActiveName) {
            vcTabs.closeActiveTab().then(resolve);
          }

          if(tabActiveName === 'services') {
            const {vcServices} = this.$refs;
            vcServices.hideContent();
          }

          if(!tabActiveName) {
            resolve();
          }
        });
      },

      toggleTab (name) {
        this.$refs.vcTabs.toggleTab(name);
      },

      titleClickHandler () {
        this.$tour.goTo('street-2');
        this.closeActiveTab();
      },

      serviceClickHandler (sceneId) {
        if (this.isUpperMd) {
          this.$tour.goTo(sceneId);
        }
      },

      servicesGoToLocationClickHandler(sceneId) {
        this.close().then(() => {
          this.$tour.goTo(sceneId);
        })
      },

      servicesToggleShowContentHandler(isShow) {
        this.isServicesShowContent = isShow;
      },

      close() {
        return this.closeActiveTab().then(this.closeNav);
      },

      closeForce() {
        this.closeActiveTab();
        this.closeNav();
      },

      navLinkClickHandler (e, data) {
        e.preventDefault();
        const {name} = data;

        this.toggleTab(name);
      },

      setSceneActiveName(name) {
        this.sceneActiveName = name;
      },

      subscribeTourEvents() {
        this.$tour.onClick(this.closeForce);
        this.$tour.onLoadScene(this.setSceneActiveName)
      }
    },
    mounted () {
      this.isMounted = true;

      setOriesizeTimeout(() => this.close());

      this.subscribeTourEvents();
    }
  };
</script>

<style src="./sass/index.scss"></style>