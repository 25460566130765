<template>
    <form class="overlay-main-form">
        <p class="overlay-main-form__title overlay-main-tab-title">Questions+Comments</p>
        <form-main-input class="overlay-main-form__group" v-bind="firstName"/>
        <form-main-input class="overlay-main-form__group" v-bind="lastName"/>
        <form-main-input class="overlay-main-form__group" v-bind="email"/>
        <form-main-input class="overlay-main-form__group" v-bind="subject"/>
        <form-main-input class="overlay-main-form__group" textarea v-bind="message"/>
        <div class="overlay-main-form__submit-l">
            <button class="overlay-main-form-submit" type="submit">Send</button>
        </div>
    </form>
</template>

<script>
    export default {
      data() {
        return {
          firstName: {
            value: '',
            name: 'firstName',
            placeholder: 'First name'
          },
          lastName: {
            value: '',
            name: 'firstName',
            placeholder: 'Last name'
          },
          email: {
            value: '',
            name: 'email',
            placeholder: 'Email'
          },
          subject: {
            value: '',
            name: 'subject',
            placeholder: 'Subject'
          },
          message: {
            value: '',
            name: 'message',
            placeholder: 'Message'
          }
        }
      }
    }
</script>