<template>
    <div class="overlay-main-tabs">
        <slot></slot>
    </div>
</template>

<script>
  export default {

    data() {
      return {
        tabActiveName: null
      }
    },

    computed: {
      tabActive() {
        return this._getTab(this.tabActiveName);
      },
      tabs() {
        return this.$slots.default
          .filter(item => item.componentInstance !==undefined)
          .map(item => item.componentInstance)
      }
    },
    methods: {
      _getTab (name) {
        return this.tabs.find(tab => tab.name === name);
      },

      closeActiveTab () {
        return new Promise(resolve => {

          const {tabActive} = this;

          if (tabActive) {

            this.$emit('tabClose', name);

            tabActive.close()
              .then(() => {
                this.tabActiveName = null;
                this.$emit('tabNameChange', null);
                this.$emit('tabClosed', name);
                resolve();
              })
              .catch(e => {});

          } else {
            resolve();
          }
        });

      },

      activateTab (name) {
        const targetTab = this._getTab(name);

        if (targetTab) {
          targetTab.open();
          this.tabActiveName = name;
          this.$emit('tabNameChange', name);
        }
      },

      toggleTab (name) {
        const {tabActiveName} = this;

        if (tabActiveName) {

          this.closeActiveTab()
            .then(() => {
              if (tabActiveName !== name) {
                this.activateTab(name);
              }
            })
            .catch(e => {});

        } else {
          this.activateTab(name);
        }
      },
    },
    render() {
      return this.$slots.default;
    },
    beforeDestroy() {
      this.tabActiveName = null;
      this.$emit('tabNameChange', null);
    }
  }
</script>