<template>
    <div v-show="isOpen" class="overlay-main-tab">
        <div class="overlay-main-tab-side">
            <div ref="elSideInnerWr" class="overlay-main-tab-side__inner_wr">
                <div v-show="isShowBtnBack" class="overlay-main-tab-side__back-l">
                    <btn-back v-on:click.native="btnBackClickHandler"/>
                </div>
                <div v-show="isShowBtnClose" class="overlay-main-tab-side__close-l">
                    <btn-close v-on:click.native="btnCloseClickHandler"/>
                </div>
                <div class="overlay-main-tab-side__inner">
                    <p class="overlay-main-tab-side__title overlay-main-tab-title">{{ title }}</p>
                    <perfect-scrollbar>
                        <div class="overlay-main-tab-side__content">
                            <slot name="side-content"/>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <template v-if="slotContentExists">
            <div class="overlay-main-tab-side _content">
                <div ref="elSideInnerWrContent" class="overlay-main-tab-side__inner_wr">
                    <div v-if="isShowBtnCloseContent" class="overlay-main-tab-side__close-l">
                        <btn-close v-on:click.native="btnCloseClickHandler"/>
                    </div>
                    <div class="overlay-main-tab-side__inner">
                        <p class="overlay-main-tab-side__title overlay-main-tab-title">Locations</p>
                        <perfect-scrollbar>
                            <div class="overlay-main-tab-side__content">
                                <slot name="content"/>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
  import BtnClose from '../btnClose';
  import BtnBack from '../btnBack';

  import MixinAnimate from './mixins/animate';

  export default {
    data () {
      return {
        isOpen: false,
        isInnerWrAnimationInProgress: false,
      };
    },
    props: {
      name: String,
      title: String,
      isShowBtnBack: Boolean
    },
    components: {
      BtnBack,
      BtnClose
    },
    mixins: [MixinAnimate],
    computed: {
      isPsContentActive () {
        return this.isUpperMd;
      },

      slotContentExists () {
        return this.$slots.content !== undefined;
      },

      isShowBtnClose () {
        const {isUpperMd, isBelowMd} = this;

        if (isBelowMd) {
          return false;
        }

        if (isUpperMd && this.$slots.content !== undefined) {
          return false;
        }

        return true;

      },
      isShowBtnCloseContent () {
        const {isUpperMd} = this;

        return isUpperMd;
      }
    },
    methods: {
      btnCloseClickHandler () {
        this.$emit('btnCloseClick');
      },
      btnBackClickHandler () {
        this.$emit('btnBackClick');
      },
      open () {
        return new Promise((resolve, reject) => {

          if (this.isInnerWrAnimationInProgress) {
            return reject();
          }

          this.isInnerWrAnimationInProgress = true;

          this.isOpen = true;

          this.$nextTick(() => {
            this.animateInnerWrOpen()
              .then(() => {
                this.isInnerWrAnimationInProgress = false;
                resolve();
              })
              .catch(e => {});
          });

        });
      },
      close () {
        return new Promise((resolve, reject) => {
          if (this.isInnerWrAnimationInProgress) {
            return reject();
          }

          this.isInnerWrAnimationInProgress = true;

          this.animateInnerWrClose()
            .then(() => {
              this.isOpen = false;
              this.isInnerWrAnimationInProgress = false;
              this.$emit('close');
              resolve();
            })
            .catch(e => {});
        });
      },
      toggle () {
        this.isOpen ? this.close() : this.open();
      }
    }
  };
</script>