import { DomUtil } from 'utils/dom';

const screen_xs = 0;
const screen_sm = 360;
const screen_md = 768;
const screen_lg = 992;
const screen_xl = 1280;

const breakpointsMin = {
  'xs': screen_xs,
  'sm': screen_sm,
  'md': screen_md,
  'lg': screen_lg,
  'xl': screen_xl,
};

const breakpointsMax = {
  'xs-max': screen_sm - 1,
  'sm-max': screen_md - 1,
  'md-max': screen_lg - 1,
  'lg-max': screen_xl - 1
};

class Breakpoints {
  constructor () {
    
    this.breakpointsMin = breakpointsMin;
    this.breakpointsMax = breakpointsMax;
    
    this.breakpoints = {
      ...breakpointsMin,
      ...breakpointsMax
    }
  }
  
  isBelow(bpName) {
    const names = Object.keys(this.breakpointsMin);
    const activeName = this.activeName;
    
    const activeIndex = names.findIndex(bp => bp === activeName);
    const targetIndex = names.findIndex(bp => bp === bpName);
    
    if(activeIndex === -1 || targetIndex === -1) {
      return false;
    }

    return activeIndex < targetIndex;
  }
  
  isUpper (bpName) {
    const names = Object.keys(this.breakpointsMin);
    const activeName = this.activeName;
    
    const activeIndex = names.findIndex(bp => bp === activeName);
    const targetIndex = names.findIndex(bp => bp === bpName);
    
    if(activeIndex === -1 || targetIndex === -1) {
      return false;
    }
    
    
    return activeIndex >= targetIndex;
  }
  
  isBetween(bpFromName, bpToName) {
    if (!bpFromName || !bpToName) {
      return false;
    }
    
    const ww = DomUtil.windowWidth;
    
    bpToName += '-max';
    
    return ww >= this.breakpointsMin[bpFromName] && ww <= this.breakpointsMax[bpToName];
  }
  
  get activeName () {
    return this.activeNames[this.activeNames.length - 1]
  }
  
  get activeNames () {
    const {breakpointsMin} = this;
    
    const names = Object.keys(breakpointsMin);
    const ww = DomUtil.windowWidth;
    
    const bpList = [];
    
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const nextName = names[i + 1];
      const val = breakpointsMin[name];
      const nextVal = breakpointsMin[nextName];
      
      if (!nextVal) {
        bpList.push(name);
        break;
      }
      
      if (ww >= val) {
        bpList.push(name);
      }
      
      if (ww < nextVal) {
        break;
      }
    }
    
    return bpList;
  }
  
  get isUpperMd() {
    return this.isUpper('md')
  }
  
  get isUpperXl() {
    return this.isUpper('xl')
  }
  
  get isBelowMd() {
    return this.isBelow('md');
  }
  
  get isBelowXl() {
    return this.isBelow('xl');
  }
}

export const breakpoints = new Breakpoints();