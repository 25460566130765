import panoramaImg from 'images/original/panorama/hall-right-part-1.jpg';
import panoramaImgSmall from 'images/original/panorama-small/hall-right-part-1.jpg';
import spotImgHallRight1 from 'images/spot/hall-right-1.jpg';

export default {
  id: 'hall-right-1',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: 65
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgHallRight1,
    title: 'hall right 1'
  },
  spotsScene: [
    {
      targetId: 'haircut',
      params: {
        x: 70
      }
    },
    {
      targetId: 'entry',
      params: {
        x: -95,
        targetX: 110
      }
    }
  ]
}