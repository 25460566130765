<template>
    <div class="overlay-main-nav">
        <div v-if="isUpperMd" class="overlay-main-nav__close-l">
            <btn-close v-on:click.native="clickCloseHandler"/>
        </div>
        <ul class="overlay-main-nav__items">
            <li v-for="item in list" class="overlay-main-nav__item">
                <a v-on:click="(e) => clickLinkHandler(e, item)"
                   v-bind:class="{'is-active': item.name === activeName}"
                   v-bind:href="item.href"
                   class="overlay-main-nav-link">
                    {{ item.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
  import btnClose from './btnClose';


  export default {
    components: {
      btnClose
    },
    props: {
      list: Array,
      activeName: String
    },
    methods: {
      clickCloseHandler() {
        this.$emit('btnCloseClick')
      },
      clickLinkHandler(e, data) {
        this.$emit('linkClick', e, data);
      }
    }
  };
</script>
