import panoramaImg from 'images/original/panorama/hall-right-part-2.jpg';
import panoramaImgSmall from 'images/original/panorama-small/hall-right-part-2.jpg';
import spotImgHallRight2 from 'images/spot/hall-right-2.jpg';

export default {
  id: 'haircut',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -175
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgHallRight2,
    title: 'hall right 2'
  },
  spotsScene: [
    {
      targetId: 'hall-right-1',
      params: {
        x: 5,
        targetX: -110
      }
    },
  ]
};