<template>
    <div class="overlay-main-services">
        <perfect-scrollbar>
            <ul ref="elItems" class="overlay-main-services__items">
                <li v-for="item in list" class="overlay-main-services__item">
                    <a href="/"
                       class="overlay-main-service"
                       v-on:click.prevent="(e) => serviceClickHandler(e, item)">
                        <div class="overlay-main-service__pic-l">
                            <div class="overlay-main-service-pic">
                                <img v-bind:src="item.imgSrc" alt="" class="overlay-main-service-pic__img">
                            </div>
                        </div>
                        <div class="overlay-main-service__text-l">
                            <p class="overlay-main-service-text">{{ item.title }}</p>
                        </div>
                        <div class="overlay-main-service__arrow-l">
                            <svg-icon name="arrow-left-long" mod="overlay-main-service-arrow"/>
                        </div>
                    </a>
                </li>
            </ul>
        </perfect-scrollbar>
        <div ref="elContent" class="overlay-main-services__content">
            <perfect-scrollbar>
                <div class="overlay-main-services__text overlay-main-text" v-html="activeText"></div>
            </perfect-scrollbar>
            <div class="overlay-main-services__btn-l">
                <button v-on:click="goToLocationClickHandler" class="overlay-main-services-btn">
                    <span class="overlay-main-services-btn__text">
                        Go to location
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {TweenLite} from 'gsap';

  export default {

    data () {
      return {
        activeSceneId: null,
        isShowContent: false
      };
    },
    computed: {
      activeText() {
        return '<h4>Nail Tips<h4/><p>If you want youthful hands, beautiful feet, glowing skin and strong nails - we can help you get there! The key to lasting results is home care and we carry the products you need to do so. If you continue the maintenance daily, you\'ll be amazed at the results and you\'ll need manicures and pedicures less frequently. Generally, it\'s best to get a manicure every 2 weeks and a pedicure every 4 weeks. These time frames can vary, based on the amount of home care an individual has in their routine.</p><h4>Classic Manicure:</h4><p>Soak the feet in hot minty sea salt. Emphasize on cutting the shape of your toe nails. Clean cuticles, removing callus from the bottom of your feet and completed with a lotion massage with polish of your choice.</p>'
      }
    },
    props: {
      list: Array
    },
    methods: {
      animateShowContent () {
        const {elItems, elContent} = this.$refs;

        const offset = 30;

        return new Promise(resolve => {

          const itemsXFrom = 0;
          const itemsXTo = elContent.offsetWidth * -1 - offset;

          const contentXFrom = elContent.offsetWidth + offset;
          const contentXTo = 0;

          TweenLite.fromTo(elItems, 0.5, {x: itemsXFrom}, {x: itemsXTo});

          TweenLite.fromTo(elContent, 0.5, {x: contentXFrom}, {
            x: contentXTo,
            onComplete: resolve
          });
        });
      },

      animateHideContent () {
        const {elItems, elContent} = this.$refs;

        const offset = 30;

        return new Promise(resolve => {

          const itemsXFrom = elItems.offsetWidth * -1 - offset;
          const itemsXTo = 0;

          const contentXFrom = 0;
          const contentXTo = elContent.offsetWidth + offset;

          TweenLite.fromTo(elItems, 0.5, {x: itemsXFrom}, {x: itemsXTo});

          TweenLite.fromTo(elContent, 0.5, {x: contentXFrom}, {
            x: contentXTo,
            clearProps: 'x',
            onComplete: resolve
          });
        });
      },


      goToLocationClickHandler() {
        this.$emit('goToLocationClick', this.activeSceneId);
      },

      serviceClickHandler(e, data) {
        this.showContent(data.sceneId);
        this.$emit('serviceClick', data.sceneId);
      },

      showContent(sceneId) {
        return new Promise(resolve => {
          if(this.isShowContent) {
            return resolve();
          }

          this._setActiveSceneId(sceneId);
          this._setShowContent(true);
          this.animateShowContent().then(resolve)
        });
      },

      hideContent() {
        return new Promise(resolve => {
          if(!this.isShowContent) {
            return resolve();
          }

          this._setActiveSceneId(null);

          this.animateHideContent()
            .then(() => {
              this._setShowContent(false);
              resolve();
            })
        });
      },

      _setActiveSceneId(id) {
        this.activeSceneId = id;
      },
      _setShowContent(isShow) {
        this.isShowContent = isShow;
        this.$emit('toggleShowContent', isShow);
      },
    }
  };
</script>