<template>
    <div class="overlay-main-contact">
        <div class="overlay-main-contact__info overlay-main-info">
            <div class="row overlay-main-info-group">
                <div class="col-12 col-xl-6 overlay-main-info-group__col">
                    <div class="overlay-main-info__item">
                        <a class="overlay-main-info-link" href="tel:+17868642900">+ 1 786 864 2900</a>
                    </div>
                    <div class="overlay-main-info__item">
                        <a class="overlay-main-info-link" href="mailto:contact@esmell.us">contact@esmell.us</a>
                    </div>
                </div>
                <div class="col-xl-6 overlay-main-info-group__col">
                    <div class="overlay-main-info__item">
                        <span class="overlay-main-info-text">Mon : 10am – 6pm</span>
                    </div>
                    <div class="overlay-main-info__item">
                        <span class="overlay-main-info-text">Teu - Sat : 9am – 9pm</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay-main-contact__form-l">
            <v-form/>
        </div>
    </div>
</template>

<script>
    import vForm from '../form';
    export default {
      components: {
        vForm
      }
    }
</script>