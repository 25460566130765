import { render, staticRenderFns } from "./Tab-mobile.vue?vue&type=template&id=3737dba9&"
import script from "./Tab-mobile.vue?vue&type=script&lang=js&"
export * from "./Tab-mobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "themes/resources/vue/components/OverlayMain/tab/Tab-mobile.vue"
export default component.exports