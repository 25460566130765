function setResizeTimeout (func, delay = 500) {
  let timeout;
  
  const timeoutFunc = () => {
    clearTimeout(timeout);
    
    timeout = setTimeout(func, delay);
  };
  
  window.addEventListener('resize', timeoutFunc);
  
  return {
    destroy: () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', timeoutFunc);
    }
  };
}

function setOrientationTimeout (func, delay = 500) {
  
  let timeout;
  
  const timeoutFunc = () => {
    clearTimeout(timeout);
    
    timeout = setTimeout(func, delay);
  };
  
  window.addEventListener('orientationchange', timeoutFunc);
  
  return {
    destroy: () => {
      clearTimeout(timeout);
      window.removeEventListener('orientationchange', timeoutFunc);
    }
  };
}

function setOriesizeTimeout (func, delay = 500) {
  if (window.bowser.isTouchable) {
    return setOrientationTimeout(() => func(), delay);
  } else {
    return setResizeTimeout(func, delay);
  }
}

export {setOriesizeTimeout, setOrientationTimeout, setResizeTimeout};