<template>
    <div v-bind:class="[className.root]" v-bind:style="rootStyle">
        <div ref="elInner" v-bind:class="className.innerEl">
            <div v-bind:class="className.textEl">
                {{location}}
            </div>
        </div>
    </div>
</template>

<script>
    import {TimelineLite} from 'gsap';

  export default {
    data() {
      return {
        width: null,
        animation: null,
        durationIn: 1,
        durationOut: 1
      }
    },
    props: {
      location: String
    },
    computed: {
      className() {
        const root = 'overlay-main-current-location';

        return {
          root,
          innerEl: root + '__inner',
          textEl: root + '__text',
        }
      },

      rootStyle() {
        return {
          width: this.width
        }
      }
    },
    methods: {
      createAnimation() {
        const {$el, $refs: {elInner}} = this;

        const tl = new TimelineLite({paused: true, delay: 0.3});

        tl.fromTo($el, 1, {
          width: 0
        }, {
          width: elInner.offsetWidth + 'px'
        });

        tl.to($el, 1, {
          width: 0
        }, '+=1');

        this.animation = tl;
      },
      destroyAnimation() {
        if(!this.animation) {
          return;
        }

        this.animation.kill({width: true});
      }
    },
    watch: {
      location() {
        this.destroyAnimation();

        this.$nextTick(() => {
          this.createAnimation();
          this.animation.play();
        });
      }
    },
    beforeDestroy() {
      this.destroyAnimation();
    }
  };
</script>