var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "overlay-main-btn-close", class: _vm.classList },
    [
      _c("svg-icon", {
        attrs: { name: "close", mod: "overlay-main-btn-close__icon" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }