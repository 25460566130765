import {addClass, toggleClass, removeClass, hasClass, getElemOffset, getClosestElem } from 'utils/dom';

class Brick {
  constructor (selector, container = document) {
    if(!selector) {
      return this;
    }
    
    if(selector instanceof HTMLElement) {
      this.elem = selector;
    } else {
      this.elem = container.querySelector(selector);
    }
  }
  
  addClass (className) {
    addClass(this.elem, className);
    return this;
  }
  
  removeClass (className) {
    removeClass(this.elem, className);
    return this;
  }
  
  toggleClass (className) {
    toggleClass(this.elem, className);
    return this;
  }
  
  hasClass (className) {
    return hasClass(this.elem, className);
  }
  
  on (event, fn) {
    if (!fn) return this;
    
    this.elem.addEventListener(event, fn);
    return this;
  }
  
  off (event, fn) {
    if (!fn) return this;
    
    this.elem.removeEventListener(event, fn);
    return this;
  }
  
  getOffset() {
    return getElemOffset(this.elem);
  }
  
  getClosest(selector) {
    return getClosestElem(this.elem, selector);
  }
}

class Bricks {
  constructor (selector, container = document) {
    if(!selector) {
      this.elems = [];
      return this;
    }
    
    if(selector instanceof NodeList) {
      this.elems = Array.prototype.slice.call(selector);
    } else if (selector instanceof Array) {
      this.elems = selector;
    } else {
      this.elems = Array.prototype.slice.call(container.querySelectorAll(selector));
    }
  }
  
  addClass (className) {
    this.elems.forEach(elem => addClass(elem, className));
    return this;
  }
  
  removeClass (className) {
    this.elems.forEach(elem => removeClass(elem, className));
    return this;
  }
  
  toggleClass (className) {
    this.elems.forEach(elem => toggleClass(elem, className));
    return this;
  }
  
  hasClass (className) {
    return this.elems.filter(elem => hasClass(elem, className));
  }
  
  on (event, fn) {
    if(!fn) return this;
    
    this.elems.forEach(elem => elem.addEventListener(event, fn));
    return this;
  }
  
  off (event, fn) {
    if(!fn) return this;
    
    this.elems.forEach(elem => elem.removeEventListener(event, fn));
    return this;
  }
  
  getOffset() {
    return this.elems.map(elem => getElemOffset(elem));
  }
}

export function brick (selector, container = document) {
  return new Brick(selector, container);
}

export function bricks (selector, container = document) {
  return new Bricks(selector, container);
}