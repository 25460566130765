import panoramaImg from 'images/original/panorama/room-1.jpg';
import panoramaImgSmall from 'images/original/panorama-small/room-1.jpg';
import spotImgRoom1 from 'images/spot/room-1.jpg';

export default {
  id: 'makeup',
  panoramaImg: {
    original: panoramaImg,
    small: panoramaImgSmall
  },
  params: {
    x: -90
  },
  spotSceneTooltipParams: {
    imgSrc: spotImgRoom1,
    title: 'makeup'
  },
  spotsScene: [
    {
      targetId: 'hall-left-1',
      params: {
        x: 100,
        targetX: -90
      }
    }
  ]
}